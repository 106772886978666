/* NotoSansKR */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('/public/assets/fonts/NotoSansKR/NotoSansKR-Thin.woff')
    format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('/public/assets/fonts/NotoSansKR/NotoSansKR-Light.woff')
    format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('/public/assets/fonts/NotoSansKR/NotoSansKR-Regular.woff')
    format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('/public/assets/fonts/NotoSansKR/NotoSansKR-Medium.woff')
    format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('/public/assets/fonts/NotoSansKR/NotoSansKR-Bold.woff')
    format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('/public/assets/fonts/NotoSansKR/NotoSansKR-Black.woff')
    format('woff');
}

/* pretendard */
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 100;
  src: url('/public/assets/fonts/pretendard/Pretendard-Thin.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 200;
  src: url('/public/assets/fonts/pretendard/Pretendard-ExtraLight.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('/public/assets/fonts/pretendard/Pretendard-Light.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('/public/assets/fonts/pretendard/Pretendard-Regular.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('/public/assets/fonts/pretendard/Pretendard-Medium.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('/public/assets/fonts/pretendard/Pretendard-SemiBold.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('/public/assets/fonts/pretendard/Pretendard-Bold.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 800;
  src: url('/public/assets/fonts/pretendard/Pretendard-ExtraBold.woff')
    format('woff');
}
@font-face {
  font-family: 'pretendard';
  font-style: normal;
  font-weight: 900;
  src: url('/public/assets/fonts/pretendard/Pretendard-Black.woff')
    format('woff');
}
