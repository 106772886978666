.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bannerBox {
  aspect-ratio: 1920 / 960;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-image: url('/public/images/home/banner-pc.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.top {
  width: 100%;
  position: fixed;
  top: 0;
  padding: 23px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: transparent;
  /* transition: all 0.5s; */
  transition: translate 0.5s;
}
.top.hide {
  translate: 0 -100px;
}

.top.active {
  background-color: #fff;
}

.bannerBox .bannerTextArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bannerBox .title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 34px rgba(0, 8, 50, 0.5);
  font-family: Agenda;
  font-size: 100px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%; /* 120px */
  text-transform: capitalize;
}

.bannerBox .desc {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 34px rgba(0, 8, 50, 0.5);
  font-family: pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 45px */
  letter-spacing: 1.2px;
}

.main {
  width: 100%;
}

.sectionArea {
  position: relative;
  height: 1000vh;
}

.sectionArea .textArea {
  position: sticky;
  top: 5vh;
  left: 0;
  margin-top: 162px;
  margin-bottom: 162px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sectionArea .textArea.inactive {
  visibility: hidden;
}

.sectionArea .textArea .title {
  color: #000;
  text-align: center;
  font-family: Agenda;
  font-size: 70px;
  font-style: italic;
  font-weight: 700;
  line-height: 128%; /* 89.6px */
  text-transform: capitalize;
}

.sectionArea .textArea .desc {
  color: #525252;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}

.stickyImageArea {
  position: sticky;
  top: 25vh;
  height: 100vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.initialImageBox {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80vw;
  height: 45vw;
  background-image: url('/public/images/home/section1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.fullImageBox {
  position: absolute;
  top: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 1;
}
.fullImageBox.section2 {
  background-image: url('/public/images/home/section2.jpg');
}
.fullImageBox.section3 {
  background-image: url('/public/images/home/section3.jpg');
}
.fullImageBox.section4 {
  background-image: url('/public/images/home/section4.jpg');
}
.fullImageBox.section5 {
  background-image: url('/public/images/home/section5.jpg');
}

.sectionTextArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  transition: all 1s;
  opacity: 0;
}

.sectionTextArea.on {
  opacity: 1;
}

.sectionTextArea .title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  font-family: Pretendard;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 80px */
  letter-spacing: -1px;
}
.sectionTextArea .desc {
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 31.2px */
}

.bottomArea {
  width: 100%;
  padding: 190px 0 230px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  box-sizing: border-box;
}

.bottomArea .textArea {
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 70px */
}

.gridArea {
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px 60px;
}

.gridArea .item {
  width: 100%;
  max-width: 620px;
}

.gridArea .item img {
  width: 100%;
  opacity: 0;
}

.gridArea .item .textArea {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  opacity: 0;
}

.gridArea .item .textArea .title {
  color: #111;
  font-family: Pretendard;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 36.4px */
  letter-spacing: -0.26px;
}
.gridArea .item .textArea .desc {
  color: #767676;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%; /* 25.92px */
  letter-spacing: -0.45px;
  text-align: left;
}

.videoArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background: url('/public/images/home/footer-pc.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  aspect-ratio: 1920 / 756;
}

.videoArea video {
  margin-top: 40px;
  width: 756px;
}

.videoArea .textArea {
  margin-top: 14px;
  padding-bottom: 120px;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.videoArea .textArea .title {
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 70px */
}

.videoArea .textArea .desc {
  color: #9d9d9d;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 31.2px */
}

.footerArea {
  width: 100%;
  padding: 60px 20px;
  background-color: #000;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.footerArea .content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1280px;
}

.footerArea .content .row {
  display: flex;
  flex-wrap: wrap;
  color: #c0c0c0;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.footerArea .content .row span::after {
  margin: 0 16px;
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
  background-color: #525252;
}

.footerArea .content .divider {
  width: 1px;
  height: 10px;
  margin: 0 16px;
  border-right: 1px solid #525252;
  translate: 0 5px;
}

.footerArea .content .copyright {
  margin-top: 30px;
  color: #999;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

.cursor {
  cursor: pointer;
}

/* TABLET */
@media screen and (max-width: 1024px) {
  .bannerBox {
    aspect-ratio: 1024 / 960;
    background-image: url('/public/images/home/banner-tab.jpg');
  }

  .bannerTextArea {
    gap: 3px;
  }

  .bannerBox .title {
    width: 550px;
  }

  .bannerBox .desc {
    width: 470px;
  }

  .sectionArea .textArea .title {
    width: 645px;
  }

  .videoArea {
    background-image: url('/public/images/home/footer-tab.png');
  }

  .footerArea .content {
    display: block;
    justify-content: flex-start;
    width: 100%;
    max-width: 1280px;
  }
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .bannerBox .top {
    padding: 17px 0;
  }
  .bannerBox .top .logo {
    width: 82px;
  }
  .bannerBox {
    aspect-ratio: 360 / 560;
    background-image: url('/public/images/home/banner-mobile.jpg');
  }

  .bannerTextArea {
    gap: 29px;
  }

  .bannerBox .title {
    font-size: 52px;
    width: 248px;
  }

  .bannerBox .desc {
    font-size: 18px;
    width: 290px;
  }
  .sectionArea .textArea {
    gap: 40px;
    margin-top: 90px;
    margin-bottom: 56px;
  }
  .sectionArea {
    height: 1300vh;
  }

  .sectionArea .textArea .title {
    font-size: 32px;
    width: 276px;
  }

  .sectionArea .textArea .desc {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #525252;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 23.68px */
    letter-spacing: -0.5px;
    width: 276px;
  }

  .sectionArea .initialImageBox {
    width: 80vw;
    height: 80vh;
  }

  .sectionTextArea {
    width: 100%;
    gap: 20px;
  }

  .sectionTextArea .title {
    font-size: 32px;
  }
  .sectionTextArea .desc {
    font-size: 16px;
  }

  .bottomArea {
    padding-top: 90px;
    padding-bottom: 98px;
    gap: 50px;
  }

  .bottomArea .textArea {
    font-size: 28px;
  }

  .gridArea {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .gridArea .item .textArea .title {
    font-size: 18px;
  }
  .gridArea .item .textArea .desc {
    font-size: 14px;
  }

  .videoArea {
    background-image: url('/public/images/home/footer-mobile.png');
  }

  .videoArea video{
    width: 300px;
    margin-top: 60px;
  }

  .videoArea .textArea {
    margin-top: 28px;
    width: 100%;
    background-color: #000;
  }

  .videoArea .textArea .title {
    font-size: 28px;
  }
  .videoArea .textArea .desc {
    font-size: 16px;
  }

  .footerArea .content .row {
    font-size: 12px;
    line-height: 20px;
  }

  .footerArea .content .divider {
    margin: 0 12px;
  }

  .footerArea .content .copyright {
    font-size: 10px;
  }
}
